// Override coreui primary color data set
$primary-base:  #3399ff;
$primary-100:   #eaf4ff;
$primary-200:   #d6eafe;
$primary-300:   #c1e0ff;
$primary-400:   #add6fe;
$primary-500:   #99ccff;
$primary-600:   #84c1ff;
$primary-700:   #70b7ff;
$primary-800:   #5badff;
$primary-900:   #47a3ff;

$primary-dark:  #004c99;
$primary:       #3399ff;
$primary-50:    #99ccff;
$primary-25:    #cce5fe;

@import "~@coreui/coreui/scss/coreui";
@import "~@coreui/icons/css/all.css";
@import "~daterangepicker/daterangepicker.css";

.corporation-deal-decline-modal {
  min-width: 560px;
  .modal-body {
    padding-left: 34px;
    padding-right: 34px;
  }
  textarea {
    height: 200px;
  }
  button {
    width: 100px;
  }
  .txt-warning {
    font-size: 0.675rem;
  }
}

.boxPDF { 
  height: 60rem; 
  width: 100%; 
  border: 1rem solid rgba(0,0,0,.1); 
}

.cursorDefault {
  cursor: default
}

.cursorText {
  cursor: text;
}

a:hover {
  color: #39f;
}

#created_at[readonly], #outcoming_sent_date[readonly] {
  background-color:unset;
}

.tbl-search {
  margin-bottom: 0 !important;
}

.tbl-search td, .tbl-search th {
  vertical-align: middle;
}

.line-height-0 {
  line-height: 0;
}
